import _objectSpread from "C:/Users/10666/Desktop/\u5B98\u7F51/www/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import top from "./components/top";
import traceabilityA from "./components/traceability-a";
import traceabilityB from "./components/traceability-b";
import traceabilityC from "./components/traceability-c";
import traceabilityD from "./components/traceability-d";
import traceabilityE from "./components/traceability-e"; // import traceabilityF from "./components/traceability-f";

import traceabilityG from "./components/traceability-g";
import traceabilityH from "./components/traceability-h";
import { mapMutations } from "vuex";
import CommonList3 from "@/components/common-list3";
export default {
  metaInfo: {
    title: "防伪溯源解决方案",
    // set a title
    meta: [{
      // set meta
      name: "keyWords",
      content: "防伪溯源,防伪溯源整体方案,RFID智能包装,RFID标签,防伪标签,防窜货系统,常达,rfid防伪溯源,防伪防窜货系统"
    }, {
      name: "description",
      content: "通过二维码、RFID电子标签等技术，对单个产品赋予身份编码及认证信息，利用先进的数据采集技术，物联网技术及大数据处理技术，实现产品在生产环节、仓库环节、物流环节、渠道销售及消费者互动等环节的追溯管理，确保产品整个生命周期过程能被追踪得到，全面提升了企业的市场反应速度和管理水平。"
    }]
  },
  components: {
    CommonList3: CommonList3,
    top: top,
    traceabilityA: traceabilityA,
    traceabilityB: traceabilityB,
    traceabilityC: traceabilityC,
    traceabilityD: traceabilityD,
    traceabilityE: traceabilityE,
    // traceabilityF,
    traceabilityG: traceabilityG,
    traceabilityH: traceabilityH
  },
  data: function data() {
    return {
      swiperTitle: "",
      swiperList: [{
        t: "仓储物流管理",
        c: "实现产品生产过程,再到产品销售及售后服务的产品全生命周期全程追溯管理。",
        icon: require("@/assets/traceability/swiper/1.png"),
        icon2: require("@/assets/traceability/swiper/1c.png")
      }, {
        t: "连接ERP系统",
        c: "与各大品牌的ERP通过API进行无缝对接。实现物料产品生产,物流调配,市场营销管理过程与ERP的管理协同。",
        icon: require("@/assets/traceability/swiper/2.png"),
        icon2: require("@/assets/traceability/swiper/2c.png")
      }, {
        t: "营销管理",
        c: "通过物联网技术的高校精准识别感知,实现了产品物流储运过程及售后服务过程的完善管理。",
        icon: require("@/assets/traceability/swiper/3.png"),
        icon2: require("@/assets/traceability/swiper/3c.png")
      }, {
        t: "连接CRM系统",
        c: "与各品牌CRM系统通过API记性无缝连接。实现物料产品运输配送管理,门店管理过程与CRM的管理协同。",
        icon: require("@/assets/traceability/swiper/4.png"),
        icon2: require("@/assets/traceability/swiper/4c.png")
      }, {
        t: "信息化改造",
        c: "产品追溯系统让物料与产品具有了数据与身份识别属性,便于生产管理,物料调配和市场营销数字化与信息化。",
        icon: require("@/assets/traceability/swiper/5.png"),
        icon2: require("@/assets/traceability/swiper/5c.png")
      }, {
        t: "线上及线下新零售",
        c: "产品追溯使线上订货线下供货过程中物流信息数据化,实现产品全程追溯,保障消费者及企业利益。",
        icon: require("@/assets/traceability/swiper/6.png"),
        icon2: require("@/assets/traceability/swiper/6c.png")
      }],
      recommend: {
        title: "防伪溯源产品推荐",
        list: [{
          image: require("@/assets/traceability/recommend/1.png"),
          to: "/product?name=NFC标签&current=3"
        }, {
          image: require("@/assets/traceability/recommend/2.png"),
          to: "/product?name=手持机"
        }, {
          image: require("@/assets/traceability/recommend/3.png"),
          to: "/product?name=RFID打印机&current=1"
        }, {
          image: require("@/assets/traceability/recommend/4.png"),
          to: "/product?name=桌面式读写器"
        }, {
          image: require("@/assets/traceability/recommend/5.png"),
          to: "product?name=NFC标签"
        }]
      },
      list: [{
        img: require("@/assets/traceability/f/1.jpg"),
        t: "防假冒",
        c: ["独特工艺技术", "加密安全芯片", "打假专用技术", "微纳防伪产品"]
      }, {
        img: require("@/assets/traceability/f/2.jpg"),
        t: "防回收",
        c: ["多重复合结构", "易碎基材", "多码关联", "多种一次性防转移技术"]
      }, {
        img: require("@/assets/traceability/f/3.jpg"),
        t: "防调包",
        c: ["区块链技术", "有机糅合多重技术的追溯系统", "可预判出厂后的调包行为"]
      }, {
        img: require("@/assets/traceability/f/4.jpg"),
        t: "全流程区块链追溯管理",
        c: ["以RFID/二维码数据为核心，实现溯源系统在线赋码、数据关联、实时同步、实时监控、实时验证、实时库存管理、实时定位跟踪等监控功能，单双向特殊指令验证。"]
      }]
    };
  },
  methods: _objectSpread({}, mapMutations(["setHeaderColorBlack", "setHeaderColorWhite"])),
  mounted: function mounted() {
    new Wow.WOW({
      animateClass: "animate__animated",
      offset: 30
    }).init();
    this.setHeaderColorWhite();
  },
  unmounted: function unmounted() {
    this.setHeaderColorBlack();
  }
};